'use client'

import React from 'react'
import {
  CellContext,
  DisplayColumnDef,
  flexRender,
  Table as ReactTable,
  Row,
} from '@tanstack/react-table'
import classnames from 'classnames'
import { UIProps } from '../types'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { Dropdown } from './Dropdown'
import { Flex } from './Flex'
import { Spinner } from './Spinner'
import { Text } from './Text'
import styles from './Table.module.scss'

function get(obj: any, path: string, defaultValue: any = undefined) {
  return String.prototype.split.call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce((a, c) => (Object.hasOwnProperty.call(a, c) ? a[c] : defaultValue), obj)
}

export type TableProps<T> = {
  table: ReactTable<T>
  className?: string
  size?: 'large' | 'medium' | 'small' | 'tag'
  loading?: boolean
  paginationTotalCount?: number
  expandedData?: {
    label: string
    path: string
    format?: (data: any) => string
  }[]
  emptyState?: React.ReactNode
  classNames?: {
    table?: string
    thead?: string
    tbody?: string
    tr?: string
    th?: string
    td?: string
  }
}

export const Table = <T,>({
  table,
  className,
  size = 'small',
  loading,
  paginationTotalCount,
  expandedData,
  emptyState,
  classNames,
}: TableProps<T>) => {
  const { rows } = table.getFilteredRowModel()
  const { pageIndex, pageSize } = table.getState().pagination

  const totalCount = paginationTotalCount || rows.length

  return (
    <TableElement as="table-container" className={className}>
      <TableElement
        as="table"
        className={classnames(styles[size], classNames?.table)}
        style={{ width: '100%' }}
      >
        <TableElement as="thead" className={classNames?.thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableElement
              as="tr"
              key={headerGroup.id}
              className={classNames?.tr}
            >
              {headerGroup.headers.map((header) => {
                const headerContent = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )
                return (
                  <TableElement
                    as="th"
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      maxWidth: header.column.columnDef.maxSize,
                      minWidth: header.column.columnDef.minSize,
                    }}
                    className={classnames(
                      header.column.columnDef.meta?.flex
                        ? header.column.columnDef.meta.flex > 0
                          ? styles.grow
                          : styles.shrink
                        : undefined,
                      styles[header.column.columnDef.meta?.align ?? 'left'],
                      classNames?.th
                    )}
                  >
                    {!header.isPlaceholder &&
                      header.column.columnDef.header &&
                      table.options.getSortedRowModel ? (
                        <Flex
                          style={{ cursor: 'pointer' }}
                          alignItems="center"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {headerContent}
                          {header.column.accessorFn && (
                            <TableSortedIndicator
                              isSorted={Boolean(header.column.getIsSorted())}
                              isSortedDesc={
                                header.column.getIsSorted() === 'desc'
                              }
                            />
                          )}
                        </Flex>
                      ) : header.column.columnDef.meta?.truncate ? (
                        <span>{headerContent}</span>
                      ) : (
                        headerContent
                      )}
                  </TableElement>
                )
              })}
            </TableElement>
          ))}
        </TableElement>
        <TableElement as="tbody" className={classNames?.tbody}>
          {totalCount
            ? table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <TableElement as="tr" className={classNames?.tr}>
                  {row.getVisibleCells().map((cell) => {
                    const cellContent = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )
                    return (
                      <TableElement
                        as="td"
                        key={cell.id}
                        style={{
                          width: cell.column.getSize(),
                          maxWidth: cell.column.columnDef.maxSize,
                          minWidth: cell.column.columnDef.minSize,
                        }}
                        className={classnames(
                          cell.column.columnDef.meta?.flex
                            ? cell.column.columnDef.meta.flex > 0
                              ? styles.grow
                              : styles.shrink
                            : undefined,
                          styles[cell.column.columnDef.meta?.align ?? 'left'],
                          classNames?.td
                        )}
                      >
                        {cell.column.columnDef.meta?.truncate ? (
                          <span>{cellContent}</span>
                        ) : (
                          cellContent
                        )}
                      </TableElement>
                    )
                  })}
                </TableElement>
                {row.getIsExpanded() ? (
                  <TableExpandedRow
                    row={row}
                    expandedData={expandedData}
                    size={size}
                  />
                ) : null}
              </React.Fragment>
            ))
            : emptyState || <EmptyState loading={loading} />}
        </TableElement>
      </TableElement>
      {table.options.getPaginationRowModel && (
        <Flex justifyContent="space-between" alignItems="center" my={5} mx={4}>
          <Flex alignItems="center" gap={2}>
            <Text variant="tag" fontWeight={700}>
              Rows per page:
            </Text>
            <Dropdown
              options={[10, 25, 50, 100].map((size) => ({
                label: size,
                value: size,
              }))}
              selected={pageSize}
              variant="secondary"
              direction="up"
              onSelect={(value) => table.setPageSize(Number(value ?? 10))}
            >
              {pageSize}
            </Dropdown>
            <Text variant="tag" ml={3}>
              Showing {pageIndex * pageSize + 1}-
              {Math.min((pageIndex + 1) * pageSize, totalCount)} of {totalCount}
            </Text>
          </Flex>
          <Flex alignItems="center" gap={2}>
            <Button
              onClick={table.previousPage}
              variant="ghost"
              size="xs"
              disabled={!table.getCanPreviousPage()}
              data-test="table-previous-page-button"
            >
              Previous
            </Button>
            <Button
              onClick={table.nextPage}
              size="xs"
              disabled={!table.getCanNextPage()}
              data-test="table-next-page-button"
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </TableElement>
  )
}

export const TableElement = ({
  as,
  className,
  children,
  ...rest
}: UIProps & {
  as: 'table-container' | 'table' | 'thead' | 'tbody' | 'th' | 'tr' | 'td'
}) => (
  <div className={classnames(as, styles[as], className)} {...rest}>
    {children}
  </div>
)

export const TableExpandIcon = ({
  onClick,
  rotated,
}: {
  onClick: React.MouseEventHandler
  rotated?: boolean
}) => (
  <Flex
    style={{ cursor: 'pointer' }}
    alignItems="center"
    onClick={onClick}
    p={2}
  >
    <Chevron rotated={rotated} />
  </Flex>
)

export const TableSortedIndicator = ({
  isSorted,
  isSortedDesc,
}: {
  isSorted: boolean
  isSortedDesc?: boolean
}) => (
  <div
    className={classnames(
      styles['chevron-group'],
      isSorted && styles[isSortedDesc ? 'descending' : 'ascending']
    )}
  >
    <Chevron rotated />
    <Chevron />
  </div>
)

const Chevron = ({ rotated }: { rotated?: boolean }) => (
  <svg
    style={rotated ? { transform: 'rotate(180deg)' } : undefined}
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3335 2.33337L5.11524 4.58304L8.97238 2.33337"
      stroke="#017ACC"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

const TableExpandedRow = <T,>({
  row,
  size,
  expandedData,
}: {
  row: Row<T>
} & Pick<TableProps<T>, 'size' | 'expandedData'>) => {
  if (!expandedData) return null

  const first = [...(expandedData ?? [])]
  const second = first.splice(Math.ceil(first.length / 2))
  const { original } = row
  return (
    <Flex
      className={classnames(
        styles.expandedRow,
        size ? styles[size] : undefined
      )}
      gap={8}
    >
      {[first, second].map((entries, i) => (
        <Flex key={`col-${i}`} className="col" direction="column">
          {entries.map(({ label, path, format }, i) => (
            <Flex key={i}>
              <Text fontWeight={700} variant={size}>
                {label}:
              </Text>
              <Text variant={size}>
                {format?.(get(original, path)) ||
                  get(original, path) ||
                  '-- No Data Available --'}
              </Text>
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  )
}

const EmptyState = ({ loading }: { loading?: boolean }) => (
  <Flex className={styles.empty} justifyContent="center" alignItems="center">
    <Flex direction="column" alignItems="center">
      {loading ? (
        <Spinner />
      ) : (
        <Text variant="large" fontWeight={700}>
          No entries
        </Text>
      )}
    </Flex>
  </Flex>
)

export const getTableSelectionColumn = <T,>({
  handleCellClick,
  checkboxProps,
}: {
  handleCellClick?: (context: CellContext<T, unknown>) => void,
  checkboxProps?: {
    header?: Record<string, string>
    cell?: (row: Row<T>) => Record<string, string>
  },
} = {}): DisplayColumnDef<T> => ({
  id: 'selection',
  header: ({ table }) => (
    <Checkbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
      ariaLabel="header checkbox"
      {...(checkboxProps?.header || {})}
    />
  ),
  cell: (context) => {
    const { row, table } = context
    return (
      <Checkbox
        ariaLabel="row checkbox"
        checked={
          row.getCanExpand()
            ? row.getIsSomeSelected()
              ? false
              : row.getIsAllSubRowsSelected()
            : row.getIsSelected()
        }
        indeterminate={row.getIsSomeSelected()}
        onClick={() => {
          if (row.getCanExpand()) {
            if (
              row.getIsSelected() &&
              (row.getIsSomeSelected() || row.getIsAllSubRowsSelected())
            ) {
              const selections = { ...table.getState().rowSelection }
              const [index] = row.id.split(':')
              for (const selectedId in selections) {
                if (selectedId.startsWith(index)) {
                  delete selections[selectedId]
                }
              }
              table.setRowSelection(selections)
            }
          }
          handleCellClick?.(context)
        }}
        onChange={(() => {
          if (row.getCanExpand()) {
            if (!row.getIsSelected() && row.getIsAllSubRowsSelected()) {
              row.toggleSelected(true)
              return () => undefined
            }
            if (
              row.getIsSelected() &&
              !row.getIsSomeSelected() &&
              !row.getIsAllSubRowsSelected()
            ) {
              row.toggleSelected(false)
              return () => undefined
            }
          }
          return row.getToggleSelectedHandler()
        })()}
        {...(checkboxProps?.cell ? checkboxProps.cell(row) : {})}
      />
    )
  },
  size: 40,
  meta: {
    align: 'center',
  },
})

export const getTableExpanderColumn = <T,>(): DisplayColumnDef<T> => ({
  id: 'expander',
  header: ({ table }) => (
    <TableExpandIcon
      onClick={table.getToggleAllRowsExpandedHandler()}
      rotated={table.getIsAllRowsExpanded()}
    />
  ),
  cell: ({ row }) => (
    <TableExpandIcon
      onClick={() => row.toggleExpanded()}
      rotated={row.getIsExpanded()}
    />
  ),
  size: 40,
  meta: {
    align: 'center',
  },
})
